.fixed_sidebar {
    position: fixed;
    top: 15px;
    width: 255px;
}

.page_products {
    .section_title {
        text-align: left;
        margin-bottom: 20px;
    }
}

.products_filter {
    border-bottom: 0;
}

.products_filter--item {
    list-style: none;
    display: block;
    float: left;
    padding: 9px 14px;
    color: $prim-color;
    border: 2px solid $prim-color;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 1.5em;
    margin-right: 8px;
    margin-bottom: 8px;
    -webkit-transition: .2s ease-in-out;
    transition: .2s ease-in-out;
    text-decoration: none;

        &.active {
            background-color: $prim-color;
            color: #fff;

                &:hover {
                    color: #fff;
                    text-decoration: none;
                }
        }
    
        &:hover {
            background-color: $prim-color;
            cursor: pointer;
            color: #fff;
            text-decoration: none;
        }
}

.all_products {
    .product_item {
        &:nth-child(1),  &:nth-child(2), &:nth-child(3){
            margin-top: 0;
        }
    }
}