.page_news {
    .news_item {
        margin-bottom: 80px;

            a {
                &:hover {
                    text-decoration: none;
                }
            }
    }
}

.news_item {
    a {
        &:hover {
            text-decoration: none;
        }
    }
}

.news_content--sidebar {
    background-color: $prim-color;
    padding: 30px;

        h3 {
            color: #fff;
            font-size: 1.8em;
            font-weight: bold;
            margin-bottom: 30px;
        }
        
        li {
            color: #fff;
            list-style: none;
        }

        a {
            color: #fff;
            font-size: 1.5em;

                &:hover {
                    text-decoration: none;
                }

                p {
                    color: #fff;
                    font-size: 1.2em;
                    margin-top: 5px;
                    text-align: center;
                }
        }
}

.sidebar_item {
    margin-bottom: 30px;
}

.news_content--header {
    text-align: left;

        h2 {
            color: $prim-color;
            font-weight: bold;
            margin-bottom: 20px;
            margin-top: 20px;
        }
}

#news_slider {
    margin-top: 30px;

        .carousel-item {
            text-align: center;
        }
}