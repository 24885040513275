#banner {
    padding-top: 0;
}

.product_banners {
    img {
        &:first-child {
            margin-bottom: 41px;
        }
    }
}

.news {
    .section_title {
        background-color: $sec-color;
        display: table;
        margin: 0 auto;
        padding: 30px 56px;
        color: #fff;
        position: relative;
        z-index: 2;
    }
}

.news_items {
    background-image: url('../img/news_bg.jpg');
    background-size: cover;
    background-position: center;
    padding-top: 80px;
    padding-bottom: 80px;
    margin-top: -40px;
}

.news_item {
    position: relative;
}

.news_item--image {
    display: inline-block;
    overflow: hidden;
    
        img {
            -webkit-transform: scale(1);
            transform: scale(1);
            -webkit-transition: .2s ease-in-out;
            transition: .2s ease-in-out;

            &:hover {
                -webkit-transform: scale(1.05);
                transform: scale(1.05);
            }
        }
}

.news_item--title {
    width: 414px;
    position: absolute;
    background-color: $sec-color;
    padding: 20px;
    right: 15px;
    font-size: 2.2em;
    font-family: $titles-font;
    color: #fff;
    text-align: center;
    bottom: 10px;
}

.news_item--btn {
    background-color: $prim-color-dark-02;
    color: #fff;
    position: absolute;
    right: 50px;
    bottom: -20px;
    font-size: 1.5em;

        &:hover {
            color: #fff !important;
        }
}