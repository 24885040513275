.navigation {
    background-color: white;
    padding-top: 15px;
    padding-bottom: 30px;
}

.navigation_brand {
    width: 26%;
}

.navigation_content {
    text-align: right;
    width: 60%;
    padding-right: 30px;
    padding-left: 30px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;

        &--info {
            font-size: 1.4em;
            color: $prim-color;
            padding-bottom: 5px;
 
        
                i {
                    font-size: .8em;
                }
        }

        &--links {
            margin-top: 5px;
            float: right;
            margin-bottom: 0;
            line-height: 10px;
        
                li {
                    list-style: none;
                    float: left;
                    font-size: 1.4em;
                    font-family: $titles-font;
                    text-transform: uppercase;
                    padding-left: 12px;
                    padding-right: 12px;
                    
                    @media screen and (max-width: 1199px) {
                        padding-right: 0px;
                    }
                        a {
                            color: #2f3033;
                            transition: .2s ease-in-out;
                        
                                &:hover {
                                    color: $prim-color;
                                    text-decoration: none;
                                }
                        }
        
                        &:visited,
                        &:active {
                            color: inherit;
                            text-decoration: none;
                        }
        
                        &:first-child {
                            padding-left: 0;
                        }
        
                        &:last-child {
                            padding-right: 0;
                        }
                }
        }
}

.navigation_button {
    background-color: $prim-color;
    color: #fff;
    font-family: $titles-font;
    font-size: 1.6em;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
    padding: 31px 20px 0px 20px;
   
    @media screen and (max-width: 1199px) {
        padding: 29px 18px 30px 20px;
    }
 

        img {
            margin-right: 16px;
            margin-top: -5px;
        }

        &:hover,
        &:visited,
        &:active {
            text-decoration: none;
            color: #fff;
        }
}

.search_header {
    display: flex;
    
        @include media-breakpoint-up (md) {
            position: absolute;
            width: 100%;
            display: contents;
        }

        @include media-breakpoint-down (sm) {
            margin-top: 10px;
        }
}

.search {
    &_input {
        border: none;
        background-color: #a11603;
        z-index: 9;
        width: calc(100% - 75px);
        padding: 10px 30px;
        color: #fff;
        font-size: 1.2em;
        
            @include media-breakpoint-up (md) {
                height: 100%;
                position: absolute;
                display: none;
            }
        
            &.active {
                display: block;
                left: 10px;
                animation-name: in;
                animation-duration: 1s;
            }

            &.disable {
                animation-name: out;
                animation-duration: 1s;
            }

            &::placeholder {
                color: #fff;
            }
    }

    &_button {
        background-color: $prim-color;
        padding: 20px 22.5px;
        @media screen and (max-width: 1199px) {
            padding: 32px 23.5px;
        }
 

        border: none;
        margin-left: 10px;
        position: relative;
        z-index: 10;
        cursor: pointer;
        
            img {
                width: 20px;
                
                &.out {
                    animation: rotate 1s;
                }
            }

            span {
                width: 20px;
                height: 20px;
                background-image: url('../img/icons/search.svg');
                display: block;
                background-size: cover;
                
                &.bg {
                    animation: bg 0.5s linear;
                    background-image: url('../img/icons/search-close.svg');

                        &.out {
                            animation: bgout 0.5s linear;
                            background-image: url('../img/icons/search.svg');
                        }
                }
            }

    }

    &_close {
        border: 0;
        position: absolute;
        right: 0px;
        background-color: transparent;
        z-index: 9;
        top: 22.5px;
        
            &.active {
                animation: close 1s;
                right: 80px;
            }

            img {
                width: 20px;
            }
    }
}

@keyframes rotate {
    from {transform: rotateZ(0deg);}
    to {transform: rotateZ(360deg);}
}

@keyframes close {
    0% {
        transform: rotateZ(0deg);
        right: 80px;
    }

    70% {
        transform: rotateZ(400deg);
        right: 90px;
    }

    100% {
        transform: rotateZ(360deg);
        right: 80px;
    }
}

@keyframes in {
    from {left: 100%}
    to {left: 10px;}
}

@keyframes out {
    from {left: 10px;}
    to {left: 100%}
}

@keyframes bg {
    0% {
        background-image: url('../img/icons/search.svg');
        transform: rotateZ(0deg)
    }

    100% {
        background-image: url('../img/icons/search-close.svg');
        transform: rotateZ(360deg)
    }
}

@keyframes bgout {
    0% {
        background-image: url('../img/icons/search-close.svg');
        transform: rotateZ(0deg)
    }

    100% {
        background-image: url('../img/icons/search.svg');
        transform: rotateZ(-360deg)
    }
}