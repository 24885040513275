.page_empresa {
    position: relative;
}

.about_content {
    padding-right: 40px;

        a {
            margin: initial;
        }
}

.about--title {
    color: $prim-color;
    font-weight: bold;
    margin-bottom: 60px;
}

.about_image {
    @media screen and (max-width: 425px) {
        margin-top: 60px;
    }
}

.about_map {
    margin-top: 80px;
}