@import "bootstrap/bootstrap.scss";

@import "layout/variables.scss";
@import "layout/elements.scss";
@import "layout/navigation.scss";
@import "layout/header.scss";
@import "layout/index.scss";
@import "layout/empresa.scss";
@import "layout/page-produtos.scss";
@import "layout/single-produtos.scss";
@import "layout/novidades.scss";
@import "layout/contact.scss";
@import "layout/orcamento.scss";
@import "layout/footer.scss";

@import "layout/responsive.scss";