#slider {
    &:hover {
        .carousel-control-next,
        .carousel-control-prev,
        .carousel-indicators {
            opacity: 1;
        }
    }

    .carousel-item {
        img {
            position: absolute;
        }
    }

    .carousel-control-next,
        .carousel-control-prev {
            width: 50px;
            height: 50px;
            top: 50%;
            transform: translateY(-50%);
            bottom: initial;
            border-radius: 100px;
            background-color: $prim-color;
            padding: 10px;
            opacity: 0;
            position: absolute;
            @include transition();

                img {
                    height: 100%;
                }
        }

        .carousel-control-next {
            margin-right: 30px;
        }

        .carousel-control-prev {
            margin-left: 30px;
        }

        .carousel-indicators {
            opacity: 0;
            
                li {
                    background-color: rgba($color: $prim-color, $alpha: 0.3);
                    width: 10px;
                    height: 10px;
                    border-radius: 100%;
                    cursor: pointer;
                }

                .active {
                    background-color: $prim-color;
                }
        }
}

.link-whatsapp{
    text-decoration: none !important;
    color:#bb1a04;
}

#prato-aramado {
    .title {
        left: 3% !important;
        top: 7% !important;
    }
}

#cone-aramado {
    .title {
        left: 3% !important;
        bottom: 5% !important;
    }
}

#linha-mesa {
    .title {
        left: 6% !important;
        top: 13% !important;
    }
}

#linha-plug {
    .title {
        right: 6% !important;
        bottom: 12% !important;
    }
}