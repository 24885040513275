* {
    margin: 0;
    padding: 0;
}

body {
    background-color: #f0f0f0;
    font-family: $content-font;
    font-size: 62.5%;
}

section {
    padding-top: 60px;
}

h1, h2, h3, h4, h5 {
    font-family: $titles-font;
}

a:not([href]):not([tabindex]) {
    color: #fff;
}

p {
    font-size: 1.7em;
    color: $content-color;
}

%inputs {
    color: #fff;
    font-family: $content-font;
    font-weight: 300;
    font-style: italic;    
    background-color: $prim-color;
    border-radius: 0;
    border: 0;
}

form {
    input {
        width: 100%;
        font-size: 1.2em;        
        padding-left: 5px;
        padding-right: 5px;
        padding-bottom: 2px;
        @extend %inputs;

            &::placeholder {
                color: #fff;
            }
    }

    button {
        color: #fff;
        font-size: 1.4em;
        padding-bottom: 5px;
        padding-right: 6px;
    }
    
    input,
    button {
        background-color: transparent;
        border: 0;
    }
}

//sections titles
.section_title {
    color: $content-color;
    font-size: 2.5em;
    text-align: center;
}

//buttons
.btn {
    color: #fff !important;
    background-color: $prim-color;
    padding: 10px;
    font-family: $titles-font;
    text-transform: uppercase;
    font-size: 1em;
    border-radius: 0;
    display: table;
    margin: 0 auto;
    margin-top: 10px;
    -webkit-transition: .2s ease-in-out;
            transition: .2s ease-in-out;

        &:hover {
            color: #fff;
            background-color: $prim-color-dark-01;
        }
}

//products sidebar
.product_categories--search {
    background-color: $prim-color-dark-01;
    padding: 10px;
}

.form-inline {
    width: 100%;
    border-bottom: 1px solid $prim-color-dark-02;
    flex-wrap: nowrap;
}

.product_categories--list {
    background-color: $prim-color;
    padding: 15px 17px;
    color: #fff;
}

.product_categories--title {
    font-size: 2em;
    font-weight: bold;
    width: 100%;
    display: block;
}

.product_categories--links {
    li {
        list-style: none;
        font-size: 1.5em;
        font-weight: 300;

            a {
                color: #fff;
                
                &:hover,
                &:visited,
                &:focus {
                    text-decoration: none;
                }
            }
    }

    .fas {
        font-size: 12px;
    }
}

.product_categories--linename {
    font-size: 1.7em;
    font-weight: bold;
    margin-bottom: 0;
}

//products
.products {
    padding-bottom: 60px;
}

.product_item {
    margin-top: 60px;

        a {
            color: inherit;

                &:hover,
                &:visited,
                &:active,
                &:focus {
                    text-decoration: none;
                }
        }
}

.product_item--image {
    margin-bottom: 10px;
    overflow: hidden;

        img {
            -webkit-transform: scale(1) rotate(0);
            transform: scale(1) rotate(0);
            -webkit-transition: .2s ease-in-out;
            transition: .2s ease-in-out;
        }

        &:hover {
            img {
                -webkit-transform: scale(1.05) rotate(1deg);
	            transform: scale(1.05) rotate(1deg);
            }
        }
}

.product_item--title {
    text-align: center;
    font-size: 1.6em;
    font-weight: bold;
    margin-bottom: 0;
}

//pages
.page_banner {
    position: relative;
    margin-bottom: 60px;
    background-size: cover;
    background-repeat: no-repeat;
    display: table;
    width: 100%;

        .container {
            max-width: 1500px;
        }

        &.banner_01 {
            background-image: url('../img/banner-produtos-01-bg.jpg');
            background-position: center bottom -350px;

                @media screen and (max-width: 768px) {
                    background-position: center center;
                }
        }

        &.banner_02 {
            background-image: url('../img/banner-produtos-02-bg.jpg');
            background-position: center top -170px;

                    @media screen and (max-width: 768px) {
                        background-position: center center;
                    }

                img {
                    margin: 0 0 0 auto;
                    display: block;
                }
        }

        &.banner_03 {
            background-image: url('../img/banner-produtos-03-bg.jpg');
            background-position: center bottom -350px;

                @media screen and (max-width: 768px) {
                    background-position: center center;
                }
        }


        &.page_banner_01 {
            background-image: url('../img/page-header-bg-01.jpg');
            background-position: center center;
        }

        &.page_banner_02 {
            background-image: url('../img/page-header-bg-02.jpg');
            background-position: center center;
        }

        &.page_banner_03 {
            background-image: url('../img/page-header-bg-03.jpg');
            background-position: center center;
        }
}

.page_banner--title {
    font-size: 4.2em;
    font-weight: bold;
    color: $prim-color;
    text-transform: uppercase;
    position: absolute;
    background-color: #fff;
    padding: 15px 20px;
    margin-bottom: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%)
}

//share
.entry-share {
    h6 {
        font-weight: 600;
        font-size: 1.7em;
        color: $sec-color;
    }

    a {
        list-style: none;
        color: #fff;
        padding: 9px 13px;
        background-color: $prim-color;
        float: left;
        margin-right: 7px;
        font-size: 1.7em;

            &:hover {
                background: darken($prim-color, 10%);
            }

            &.facebook {
                padding: 9px 13.5px;
            }

            &.twitter {
                padding: 9px;
            }

            &.gplus {
                padding: 9px 7px;
            }

            &.pinterest {
                padding: 9px 11px;
            }

            &.linkedin {
                padding: 9px 10.5px;
            }
            
            &.email {
                padding: 9px;
            }

            &.whatsapp {
                padding: 9px 10.5px;
            }
    }
}