@media screen and (max-width: 575px) {
    p {
        font-size: 1.5em;
    }

    .page_banner--title {
        font-size: 2.2em;
    }

    .news_content {
        h2 {
            font-size: 2.2em;
        }
    }

    .post_image {
        margin-bottom: 30px;
    }
    
    .contact_form {
        margin-top: 30px;
    }

    .footer_fb {
        display: none;  
    }

    .footer_brand {
        text-align: center;
    }

    footer {
        .navigation_content--info {
            text-align: center;
            margin-top: 20px;
            margin-bottom: 20px;
        }

        .navigation_button {
            width: 50%;
        }
    }
}

@media screen and (max-width: 991px) {
    .news_item {
        margin-bottom: 0;
    }

    .news_item--image {
        margin-bottom: 0;
    }

    .news_item--title {
        position: static;
        width: 100%;
        font-size: 1.6em;
        margin-top: -1px;
    }

    .news_item--btn {
        position: static;
    }
}

@media screen and (max-width: 767px) {
    .navigation_brand {
        width: 60%;
    }

    .navigation_content {
        text-align: center;
        width: 100%;
        justify-content: center;
        margin-bottom: 20px;
        margin-top: 20px;
    }

    .navigation_content--links {
        float: left;
        width: 100%;

            li {
                float: initial;
                padding: 0;
                margin-top: 10px;
                margin-bottom: 10px;
            }
    }

    .navigation_content--info {
        width: 100%;
    }

    .navigation_button {
        display: block;
        width: 100%;
        margin: 0 auto;
    }
    
    .news_item {
        &:first-child {
            margin-bottom: 60px;
        }
    }

    .news_item--image {
        display: table;
        margin: 0 auto;
    }

    .product_banners {
        a {
            &:first-child {
                img {
                    margin-top: 41px;
                    margin-bottom: 20px;
                }
            }

            &:nth-child(2) {
                img {
                    margin-top: 0px;
                }
            }
        }
    }

    #sidebar {
        margin-bottom: 30px;
    }

    .product_categories--title[aria-expanded='true']:after {
        font-family: 'Font Awesome\ 5 Free';
        content: '\f077';
        float: right;
    }

    .product_categories--title[aria-expanded='false']:after {
        font-family: 'Font Awesome\ 5 Free';
        content: '\f078';
        float: right;
    }

    .fixed_sidebar {
        position: static;
        top: initial;
        width: initial;
    }

    .all_products {
        .product_item {
            &:nth-child(2), &:nth-child(3){
                margin-top: 60px;
            }
        }
    }

    .page_header {
        background-position: center center;
        padding: 60px 30px;
    }
}

@media screen and (min-width: 576px) and (max-width: 991px) {
    .footer_brand,
    .footer_fb {
        display: none;  
    }

    footer {
        .navigation_content--info {
            max-width: 60%;
            flex: initial;
        }

        .navigation_button {
            max-width: 40%;
            flex: initial;
        }
    }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
    .navigation .navbar-collapse {
        margin-top: 20px;
    }

    .navigation_content {
        float: left;
        width: 69%; 
    }

    .navigation_button {
        display: inline-block;
        width: 30%;
    }


}

@media screen and (min-width: 768px) {
    #sidebar .collapse:not(.show) {
        display: block;
    }

    #sidebar .collapse {
        display: block;
    }
}

@media screen and (min-width: 1200px) {
    .navbar-expand-lg .navbar-collapse {
        justify-content: flex-end;
        align-items: stretch;
        position: relative;
        overflow: hidden;
    }
    
    .navigation_brand {
        width: 30%;
    }
}