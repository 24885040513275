.breadcrumb {
    background-color: transparent;
    justify-content: center;
    font-size: 1.5em;
    margin-bottom: 60px;

        .breadcrumb-item {
            a {
                color: $prim-color;

                    &:hover {
                        text-decoration: none;
                    }
            }
        }
}

#product_slider {
    .carousel-item {
        p {
            position: absolute;
            bottom: 30px;
            right: 30px;
            background-color: $prim-color;
            padding: 10px;
            margin: 0;
            color: #fff;
        }
    }
    .carousel-indicators {
        position: relative;
        bottom: 0;
        margin-left: 0;
        margin-right: 0;
        flex-wrap: wrap;

            li {
                width: initial;
                height: initial;
                text-indent: initial;
                text-align: center;
                background-color: transparent;
                margin-top: 20px;

                    img {
                        text-indent: initial;
                        max-width: 65px;
                        width: initial;
                        height: initial;
                        background-color: #fff;
                    }

                    &.active {
                        img {
                            border: 3px solid $prim-color;
                        }
                    }

                    p {
                        font-size: 1.2em;
                        margin-top: 5px;
                        margin-bottom: 0;
                    }
            }
    }
}

.carousel-control-next,
.carousel-control-prev {
    color: $prim-color;
    font-size: 4.8em;
    z-index: 9999999;
    bottom: initial;
    top: 50%;
    transform: translateY(-50%);
}

.carousel-control-next:focus,
.carousel-control-next:hover,
.carousel-control-prev:focus,
.carousel-control-prev:hover {
    color: $prim-color;
}

.product_title {
    color: $prim-color;
    font-size: 2.9em;
    font-weight: bold;
    text-align: left;
    margin-bottom: 50px;
}

.table_header {
    background-color: #eaeaea;    
    border: 0 !important;

        th {
            border: 0 !important;
            font-size: 13px;
            font-weight: bold; 
            color: $content-color;
        }
}

.table_body {
    th,
    td {
        border-top: 0;
        font-size: 13px;
        color: $content-color;
        font-weight: 400;
    }

    tr {
        border-bottom: #dddcdc solid 1px;

            &:last-child {
                border-bottom: 0;
            }
    }
}

.table {
    border-bottom: 7px solid #eaeaea;
}

.product_share {
    font-weight: 600;
    font-size: 1.7em;
    color: $sec-color;
}

.product_share--links {
    li {
        list-style: none;
        color: #fff;
        padding: 9px 13px;
        background-color: $prim-color;
        float: left;
        margin-right: 7px;

            &.facebook {
                padding: 9px 17.5px;
            }

            &.g-plus {
                padding: 9px 11px;
            }

            &.pinterest {
                padding: 9px 15.5px;
            }

            &.linkedin {
                padding: 9px 14px;
            }
    }
}