footer {
    background-color: #fff;
    padding-top: 12px;
    padding-bottom: 30px;
    margin-top: 60px;
}

footer {
    .row {
        align-items: center;
    }

    .navigation_button {
        font-size: 1.3em;
        padding-bottom: 20px;
    }
}

.site-info {
    background-color: #fff;
    width: 100%; 
    padding-top:10px;
    padding-bottom:5px;
    font-size: 80%; 
    p {
        margin-top: 0;
        margin-bottom: 0rem;
    }
}
