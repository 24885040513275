.header_orcamentos {
    padding-top: 60px;
    padding-bottom: 60px;
    background-color: $prim-color;
    color: #fff;

        h2 {
            font-family: $titles-font;
            font-weight: bold;
            font-size: 3.7em;
            margin-bottom: 30px;
        }

        p {
            color: #fff;
        }
}

.page_orcamentos {
    padding-top: 60px;
}

.orcamento_form {
    input {
        width: 100%;
    }
}

.orcamentos_items {
    margin-top: 80px;
}

.orcamento_item {
    margin-bottom: 20px;
}

.orcamentos_items--name {
    font-size: 1.7em;
}

.orcamentos_items--input {
    input {
        width: 100%;
        border-radius: 10px;
        border: 1px solid #d3d3d4;
        padding: 10px;
        text-align: center;
        font-size: 1.4em;
        background-color: #fff;
        color: #000;
        font-style: initial;
    }
}

.ref_title, .quant_title {
    font-size: 1.5em;
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 30px;
}