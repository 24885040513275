.contact_info {
    margin-bottom: 20px;
    
        p {
            color: $prim-color;
            margin-bottom: 5px;
        }
}


.contact_form {
    input {
        @extend %inputs;
        padding: 10px 20px 20px 20px;
        margin-bottom: 10px;
        font-size: 1.7em;
        width: 49%;
        float: left;

            &::placeholder {
                color: #fff;
                border-bottom: 1px solid $prim-color-dark-02;
                padding-bottom: 5px;
            }

            &:nth-child(even) {
                margin-left: 2%;
            }
    }

    textarea {
        width: 100%;
        @extend %inputs;
        font-size: 1.7em;
        padding: 10px 20px 20px 20px;
        border: 0;

            &::placeholder {
                color: #fff;
            }
    }
}

.contact_form--info {
    font-size: 1.1em;
    margin-left: 0;
    margin-right: 0;
    margin-top: 10px;
        
        b {
            color: $prim-color;
        }

        .btn {
            margin-top: 0;
        }
}